// --- (1), (2) & (3): install and import ---
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup, Polyline, useMap, FeatureGroup } from 'react-leaflet';
import { Icon, DivIcon} from 'leaflet';
import { getMigrationsData } from "./Api.js";

import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import './svg-icon.js';
import "leaflet-polylinedecorator";

import { htmlToText } from 'html-to-text';
import jsxToString from 'jsx-to-string';



// --- ---------------------------------- ---


function idToColor(id) {
  let hash = 0;
  id = id.toString();
  for (let i = 0; i < id.length; i++) {
    hash = id.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
}

function customMarkerIcon(color, circleText, fillOpacity) {
  
  return new DivIcon.SVGIcon({
    circleText: circleText,
    fontColor: color,
    fillColor: color,
    color: color,
    circleColor: color,
    fontSize: 11,
    opacity: fillOpacity,
    circleOpacity: fillOpacity,
    circleFillOpacity: fillOpacity,
    fillOpacity: fillOpacity,
    fontOpacity: fillOpacity,
    iconSize: [28, 36],
    circleWeight: 1,
  });
}

function getMarkerOffset () {
  //add tiny random offset to keep markers from dropping on top of themselves
  const offset =Math.random()/20;
  const isEven = (parseInt(offset *400000)) %2 ==0;
  if (isEven) return  offset;
  else        return -offset;
}

function authorPopupContent(authordata, location_data=null) {
  const limit = 170;
  const bio_stripped= htmlToText(authordata?.biography, {});
  const authorUrl = `https://zemelah.online/authors/${authordata?.slug}`

  const bio_url = bio_stripped?.length > limit ? (<a href={authorUrl} target="_blank">...</a>) : '';

  return (
    <div>
      {location_data ? <h4>{location_data.locationname}</h4> : ''}
      <br/>
      <h5><a href={authorUrl} target="_blank">{authordata?.full_name}</a></h5>
      <br/>
      <span>{bio_stripped?.substring(0, limit)}</span> {bio_url}
      {location_data ? <p><cite>{location_data.citation}</cite></p> : ''}
      <br />
      {location_data ? <small>{location_data[''] ? '*Недобровольное переселение' : ''}</small> : ''}
    </div>

  ) 
}

export function Map() {

  const AuthorMarkers = ({ color, data, authordata, polyline }) => {

    return data.map((item, index) => {
      //const coords = item.xyz.split(',');
      const fillOpacity = item[''] ? 0.7 : 0.3;

      return <Marker
        key={index}
        icon={customMarkerIcon(color, index + 1, fillOpacity)}
        position={{ lat: item.xyz[0], lng: item.xyz[1] }}
        eventHandlers={{
          mouseover: (event) => polyline.fire("mouseover"), // highlight polyline on hovering over marker
          mouseout: (event) => { // make sure polyline remains highlighted when popup is open
            if (!event.target.isPopupOpen()) {
              polyline.fire("mouseout")
            }
          },
          popupclose: (event) => polyline.fire("mouseout"), // unhighlight polyline on closing popup
        }}
      >
        <Popup>
          {authorPopupContent(authordata, item)}
        </Popup>
      </Marker>
    });
  };

  function PolylineDecorator({ patterns, polyline, popupContent, options, slug, setpolyline }) {

    const map = useMap();

    useEffect(() => {
      if (!map) return;

      var pl = L.polyline(polyline, options).addTo(map);
      L.polylineDecorator(pl, {
        patterns
      }).addTo(map);

      setpolyline(pl);

      pl.on({
        mouseover: function (e) {

          this.setStyle({ weight: 6, opacity: 0.7});
        },
        mouseout: function (e) {
          e.target.closePopup();
          this.setStyle({ weight: 3, opacity: 0.1 });
        },
        click: function (e) {
          const author_slug = slug.replace('-map', '');
          //window.location.href = `https://zemelah.online/authors/${author_slug}`;
          //window.open(`https://zemelah.online/authors/${author_slug}`, '_blank');
          var popup = L.popup()
          .setLatLng(e.latlng)
          .setContent(popupContent)
          .openOn(map);
        }
      });


    }, [map]);

    return null;
  }

  const AuthorPolyline = ({ color, data, authordata, slug, setpolyline }) => {
    const points = data.map((item, index) => {
      return item.xyz;
    });
    /*return <Polyline
          color={color}
          opacity={0.7}
          weight={6}
          positions={points}
        ></Polyline>
    */
    const options = {
      color: color,
      opacity: 0.1,
      weight: 2,
      stroke: true
    }
    // Polyline decorator
    const arrow = [
      {
        offset: '1%',
        repeat: '5%',
        symbol: L.Symbol.arrowHead({
          pixelSize: 15,
          polygon: false,
          pathOptions: options
        })
      }
    ];

    return <PolylineDecorator patterns={arrow} polyline={points} popupContent={jsxToString(authorPopupContent(authordata))} options={options} slug={slug} setpolyline={setpolyline}/>

  }


  const AuthorMigrations = ({ id, geodata, authordata }) => {
    const [polyline, setPolyline] = useState(null);

    const color = idToColor(id);

    const geoitems = geodata.map((item, index) => {
      //console.log(item);
      var new_item = item;
      if (Array.isArray(item.xyz)) {
        return new_item
      }
      const point = Array.isArray(item.xyz) ? item.xyz : item.xyz.split(',');
      new_item.xyz = [parseFloat(point[0]) + getMarkerOffset(), parseFloat(point[1] + getMarkerOffset())]
      return new_item;
    });

    return (
      <div>
        <AuthorMarkers color={color} data={geoitems} authordata={authordata} polyline={polyline} />
        <AuthorPolyline color={color} data={geoitems} authordata={authordata} slug={id} setpolyline={setPolyline} />
      </div>
    )
  }

  const AuthorsContainer = ({ authorData }) => {
    const map = useMap();
    if (!authorData) {
      return;
    }
    const authors = authorData.map((author, index) => {
      return (
        <FeatureGroup key={index} ref={groupRef}>
          <AuthorMigrations id={author.slug} geodata={author.fields.geonames} authordata={author.fields[""][0]?.fields} />
        </FeatureGroup>
      )
    });

    if (groupRef.current) {
      map.fitBounds(groupRef.current.getBounds());    
    }

    return authors;

  }

  const groupRef = useRef();
  let [searchParams, setSearchParams] = useSearchParams();
  let [data, setData] = useState(null);
  // Moscow coordinates
  const position = [55.75222, 37.61556]

  useEffect(() => {
    const slugs = searchParams.get('authors') ? searchParams.get('authors').split(',') : null;
    getMigrationsData(slugs).then(mData => {
      setData(mData.data);
    })
  }, []);

  return (
    <MapContainer center={position} zoom={6} scrollWheelZoom={true} attributionControl={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      // --- (7) Alternative map style (attribution and url copied from the leaflet extras website) ---
      // attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
      // url='https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png'
      // --- -------------------------------------------------------------------------------------- ---
      />
      <AuthorsContainer authorData={data} />
    </MapContainer>
  )
}