import { BrowserRouter } from "react-router-dom";
import { Map } from './components/Map';
import LeafletLogo from './assets/leafletlogo.png';
import ReactIcon from './assets/reacticon.png';

function App() {
  return (
    <div className="App">
        <BrowserRouter>
          <Map />
        </BrowserRouter>

    </div>
  );
}

export default App;
