
//const API_URL = "http://localhost:8000" // process.env.REACT_API_URL; 
const API_URL = "https://zemelah.j-story.org/api" // process.env.REACT_API_URL; 
const MAP_SUFFIX = "-map"


const getMigrationsData = async (slugs) => {
  let slugs_str = "";
  if (slugs) {
    slugs_str = slugs.map((slug, index) => slug.endsWith(MAP_SUFFIX) ? slug :  slug + "-map").join(',')
    slugs_str = `?slug=${slugs_str}`;
  } 
  const request = await fetch(API_URL + slugs_str);
  const data = await request.json();
  return data;
};

export {getMigrationsData} 
